import { Controller } from "@hotwired/stimulus";
import { initTooltips } from 'flowbite'


// Connects to data-controller="storefront--add-to-cart"
export default class extends Controller {

  connect() {
    // hack to make tooltips work because flowbite does not listen to turbo:frame-load events https://github.com/themesberg/flowbite/issues/88
    initTooltips(); 
  }

}