import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js";

export default class extends Controller {
  static targets = ["trackedProduct"]

  connect() {
    this.viewedProducts = []
    this.syncQueue = []

    this.syncFunction = setInterval(this.syncViews.bind(this), 2000)

    this.trackedProductTargets.forEach((target) => {
      this.observer.observe(target)
    })
  }

  disconnect() {
    this.observer.disconnect()
    clearInterval(this.syncFunction)
  }

  trackedProductTargetConnected(element) {
    this.observer.observe(element)
  }

  handleProductViews(entries, _) {
    entries.forEach((entry) => {
      if (entry.isIntersecting && this.productNotAlreadySeen(entry.target.dataset.productId)) {
        this.viewedProducts.push(entry.target.dataset.productId)
        this.syncQueue.push(entry.target.dataset.productId)
      }
    })
  }

  syncViews() {
    if (this.syncQueue.length > 0) {
      const productIds = this.syncQueue.splice(0, this.syncQueue.length)
      post("/product_views", { body: { product_ids: productIds, location: window.location.pathname }})
    }
  }

  productNotAlreadySeen(productId) {
    return !this.viewedProducts.includes(productId)
  }

  // We are setting the observer here rather than in `connect`.
  // The `trackedProductTargetConnected` callback can happen before
  // the `connect` callback, hence we need this.observer set in both cases
  get observer() {
    return this._observer || this.createObserver()
  }

  createObserver() {
    let options = {
      rootMargin: "0px",
      threshold: 0.90,
    }
    this._observer = new IntersectionObserver(this.handleProductViews.bind(this), options)
    return this._observer
  }
}
