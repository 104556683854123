import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["menu", "arrow"]

  connect() {
    this.storageKey = `filterMenuOpen-${window.location.pathname}`

    // Bind event handler
    document.addEventListener("turbo:visit", this.handleTurboVisit)
    this.restoreFilterState()
  }

  disconnect() {
    // Clean up event handler
    document.removeEventListener("turbo:visit", this.handleTurboVisit)
  }

  toggle() {
    this.menuTarget.classList.toggle("hidden")
    const isOpen = !this.menuTarget.classList.contains("hidden")
    localStorage.setItem(this.storageKey, isOpen)
    this.arrowTarget.classList.toggle("rotate-180")
  }

  restoreFilterState() {
    const isOpen = localStorage.getItem(this.storageKey) === "true"
    if (isOpen) {
      this.menuTarget.classList.remove("hidden")
      this.arrowTarget.classList.add("rotate-180")
    } else {
      this.menuTarget.classList.add("hidden")
      this.arrowTarget.classList.remove("rotate-180")
    }
  }

  handleTurboVisit = () => {
    // User is navigating away from the page; reset the filter state
    localStorage.removeItem(this.storageKey)
  }
}
