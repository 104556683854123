import { Controller } from "@hotwired/stimulus"
import { patch } from "@rails/request.js"
import Uploader from "../design_editor/uploader"
import Preview from "../design_editor/preview"
import { Canvas } from "../design_editor/canvas"
import ObjectList from "../design_editor/object_list"
import { nextFrame } from "../helpers"
import { JSON_EXPORT_PROPERTIES_TO_INCLUDE } from "../design_editor/constants"
import { BLANK_OBJECT_ID } from "../design_editor/constants"

export default class extends Controller {
  static targets = [
    "canvasElement",
    "canvasContainer",
    "objectsContainer",
    "contextualMenuContainer",
    "saveBtn",
  ]

  static values = {
    visualUrl: String,
    directUploadUrl: String,
    blobTemplateUrl: String,
    editElementUrl: String,
    productDesignId: Number,
    designConfigurationId: Number,
    canvasData: Object
  }

  connect() {
    console.log(this.visualUrlValue);
    this.canvas = Canvas.initWithData(
      this,
      this.canvasElementTarget,
      this.canvasDataValue,
      {
        visualUrl: this.visualUrlValue,
        ...this.viewportDimensions
      },
      (canvas) => {
        this.objectList = new ObjectList(canvas, this.objectsContainerTarget)
      }
    )
  }

  disconnect () {
    document.removeEventListener('keydown', this.handleKeydown)
  }

  save() {
    if (this.productDesignIdValue && this.designConfigurationIdValue) {
      this.saveBtnTarget.textContent = "Saving...";
      this.canvas.clearSnapping();
      const uploader = new Uploader(this.directUploadUrlValue)
      const preview = new Preview(this.canvas, uploader)

      preview.create((blob) => {
        const payload = {
          data: this.canvas.toJSON(JSON_EXPORT_PROPERTIES_TO_INCLUDE),
          preview: blob.signed_id
        }

        patch(
          `/design/product_designs/${this.productDesignIdValue}/design_configurations/${this.designConfigurationIdValue}`,
          {
            body: payload,
          }
        ).then((response) => {
          if (response.ok) {
            this.saveBtnTarget.textContent = "Save design";
          }
        });
      })
    } 
  }

  selectImage() {
    const fileInput = document.createElement("input")
    fileInput.type = "file"
    fileInput.style.display = "none"
    fileInput.multiple = true
    fileInput.accept = "image/*"
    fileInput.addEventListener("change", this.addImageToCanvas.bind(this))
    document.body.appendChild(fileInput)
    fileInput.click()
    fileInput.remove()
  }

  addImageToCanvas(event) {
    const fileInput = event.target
    const uploader = new Uploader(this.directUploadUrlValue)

    Array.from(fileInput.files).forEach(file => {
      this.canvas.createImage(file, (imageBox) => {
        uploader.upload(file, (blob) => {
          imageBox.set({ sgid: blob.signed_id, src: this.createBlobURL(blob) })
        })
      })
    })
  }

  addText() {
    this.canvas.createText()
  }

  selectObject(event) {
    const { objectId } = event.currentTarget.dataset
    this.canvas.selectObject(objectId)
  }

  selectionUpdated(selectedElements) {
    this.objectList.setSelection(selectedElements)
    this.setContextualMenu(selectedElements)
  }

  setContextualMenu(selectedElements) {
    if (selectedElements.length == 1) {
      const selectedElement = selectedElements[0]

      let elementType

      switch(selectedElement.type) {
        case "GTextbox":
          elementType = "texts"
          break
        case "GImagebox":
          elementType = "images"
          break
      }

      if (!elementType) return

      const contextualMenuURL = this.editElementUrlValue
        .replace(":element_type", elementType)
        .replace(":id", selectedElement.id)

      this.contextualMenuContainerTarget.src = contextualMenuURL
    } else {
      this.contextualMenuContainerTarget.src = window.location.href
    }
  }

  toggleBlankControls() {
    // Toggle the text options panel based on selection
    const activeObject = this.canvas.getActiveObject();
    const optionsPanel = this.element.querySelector("#blank-controls");
    if (activeObject && activeObject.id === BLANK_OBJECT_ID) {
      optionsPanel.classList.remove('hidden');
      // Set the color picker value to the selected object's color
      const blankColorInput = this.element.querySelector('[data-design-editor-target="blankColorInput"]');
      blankColorInput.value = this.canvas.blankColor || '#ffffff';
    }else{
      optionsPanel.classList.add('hidden');
    }
  }

  changeBlankColor(event) {
    const color = event.target.value;
    const activeObject = this.canvas.getActiveObject();
    if (activeObject && activeObject.id === BLANK_OBJECT_ID) {
      this.canvas.setBackgroundColor(color);
    }
  }

  updateElement(id, name, value) {
    const element = this.canvas.findObject(id)

    if (element.constructor.parameterHandlers && element.constructor.parameterHandlers[name]) {
      value = element.constructor.parameterHandlers[name](value)
    }

    element.set(name, value)
    element.parameterUpdated()
    this.objectList.render()
    this.canvas.renderAll()
  }

  toggleTextOptions() {
    // Toggle the text options panel based on selection
    const activeObject = this.canvas.getActiveObject()
    const textOptionsPanel = this.element.querySelector("#text-options")
    if (activeObject && activeObject.type === 'GTextbox') {
      textOptionsPanel.classList.remove('hidden')
      textOptionsPanel.setAttribute("data-design-editor--element-form-object-id-value", activeObject.id)
      textOptionsPanel.dataset.controller = "design-editor--element-form"
    } else {
      textOptionsPanel.classList.add('hidden');
      textOptionsPanel.dataset.controller = ""
    }
  }

  // TOGGLE IMAGES OPTIONS
  toggleImageOptions() {
    const activeObject = this.canvas.getActiveObject()
    const optionsPanel = this.element.querySelector("#image-options")

    if (activeObject && activeObject.type === 'GImagebox') {
      optionsPanel.classList.remove('hidden');
      // set image position inputs
      optionsPanel.setAttribute("data-design-editor--element-form-object-id-value", activeObject.id)
      optionsPanel.dataset.controller = "design-editor--element-form"
    } else {
      optionsPanel.classList.add('hidden')
      optionsPanel.dataset.controller = ""
    }
  }

  // Start image options

  replaceImage(event) {
    const file = event.currentTarget.files[0];
    if (!file) return;

    const activeObject = this.canvas.getActiveObject();
    if (!activeObject || activeObject.type !== 'GImagebox') return;

    const reader = new FileReader();
    reader.onload = (e) => {
      const dataURL = e.target.result;
      fabric.Image.fromURL(dataURL, (newImage) => {
        activeObject.image = newImage;
        activeObject.scaleImageToFitBoundaries();
        this.canvas.requestRenderAll();
      });
    };
    reader.readAsDataURL(file);
  }
  // End image options

  async resizeCanvas() {
    await nextFrame()
    this.canvas.resize(this.viewportDimensions)
  }

  removeObject(event) {
    event.stopPropagation()
    const objectId = event.target.closest("li").dataset.objectId
    this.canvas.removeObject(objectId)
  }

  refreshObjectList() {
    this.objectList.render()
  }

  createBlobURL(blob) {
    return this.blobTemplateUrlValue
      .replace(":signed_id", blob.signed_id)
      .replace(":filename", encodeURIComponent(blob.filename))
  }

  handleKeydown(event) {
    if (event.target.id == "tag") {
      return;
    }
    if (event.key === 'Backspace' || event.keyCode === 8 || event.key === 'Delete' || event.keyCode === 46) {
      const activeObject = this.canvas.getActiveObject();
      if (activeObject) {
        const objectId = activeObject.id;
        if ( activeObject.type !== 'GTextbox' || !activeObject.isEditing ) {
          event.preventDefault();
          this.canvas.removeObject(objectId);
          this.refreshObjectList();
        }
      }
    }
  }

  get viewportDimensions() {
    return {
      width: this.canvasContainerTarget.clientWidth,
      height: this.canvasContainerTarget.clientHeight
    }
  }
}
