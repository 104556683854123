import { dataURLtoBlob } from "../helpers"

export default class Preview {
  constructor(canvas, uploader) {
    this.canvas = canvas
    this.uploader = uploader
  }

  create(callback) {
    const file = new File([this.blob], "design_configuration.png", { type: "image/png" })
    this.uploader.upload(file, (blob) => callback(blob))
  }

  get blob() {
    return dataURLtoBlob(this.dataURL)
  }

  get dataURL() {
    return this.canvas.toDataURL({
      format: "png",
      enableRetinaScaling: true,
      ...this.dimensions
    })
  }

  get dimensions() {
    const { width, height, top, left } = this.canvas.blankArea
    return { width, height, top, left }
  }
}
