import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  // usage: data-controller="link-to" data-action="click->link-to#click" data-link-to-url-value="<%= <url> %>"

  static values = {
    url: String
  }
  
  click(e) {
    // check if target has a class `no-link`
    if (!e.target.href && !(e.target.classList.contains('no-link'))) {
      Turbo.visit(this.urlValue)
    }
  }
}
