export const BLANK_OBJECT_ID = "blank"

export const GIFTSHOP_OBJECT_TYPES = [
  "GTextbox",
  "GImagebox"
]

export const JSON_EXPORT_PROPERTIES_TO_INCLUDE = [
  "backgroundColor",
  "blankColor",
  "lockMovementX",
  "lockMovementY",
  "lockRotation",
  "lockScalingX",
  "lockScalingY",
  "hasControls",
  "selectable",
  "evented",
  "blankArea",
  "id",
]
