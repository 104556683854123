import { Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "dialog", "content" ]

  open(e) {
    e.preventDefault()
    const link = e.currentTarget
    this.dialogTarget.showModal()
    this.loadingContent = this.contentTarget.innerHTML
    this.contentTarget.src = link.href
  }

  safeClose(event) {
    if (this.contentTarget.contains(event.target)) return

    this.close()
  }

  close(e) {
    this.dialogTarget.close()
    this.resetContent()
  }

  resetContent() {
    this.contentTarget.innerHTML = this.loadingContent
  }
}
