import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "submit" ]

  submitStart(event) {
    this.disableSubmits()
  }

  disableSubmits() {
    this.submitTargets.forEach(submitTarget => submitTarget.disabled = true)
  }
}
