import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {

  static values = {
    currentColor: { type: String, default: "" },
    colorImages: { type: Object, default: {} },
    currentViewId: { type: String, default: "" },
    currentViewData: { type: Object, default: {} }
  }

  static outlets = [
    "blanks--self-design--canvas",
    "blanks--self-design--gallery-thumbnail"
  ]

  static targets = [
    "saveForm",
    "submitButton"
  ]

  connect() {
    if (this.currentColorValue == '') {
      this.currentColorValue = Object.keys(this.colorImagesValue)[0];
    }
    this.currentViewDataValue = this.colorImagesValue[this.currentColorValue][this.currentViewIdValue];
  }

  currentViewIdValueChanged(value, previousValue) {
    if (value == '' || value === previousValue) {
      return false
    }

    this.setCurrentCanvas(value);
    this.setCurrentThumbnail(value);
  }

  addArtwork(url, artworkId) {
    this.currentCanvas.addArtwork(url, artworkId);
  }

  updateCurrentColor(color) {
    this.currentColorValue = color;
    this.currentViewDataValue = this.colorImagesValue[this.currentColorValue][this.currentViewIdValue];
  }

  currentViewDataValueChanged(value, previousValue) {
    setTimeout(function() {
      // When color change, we update all background of all canvas
      this.blanksSelfDesignCanvasOutlets.forEach(function(outlet) {
        Object.entries(this.colorImagesValue[this.currentColorValue]).forEach(([viewId, data]) => {
          if (viewId == outlet.currentViewIdValue) {
            outlet.updateBackgroundImage(this.colorImagesValue[this.currentColorValue][viewId], viewId);
          }
        });
      }.bind(this));
    }.bind(this), 0);
  }

  updateCurrentViewId(data, blankViewId) {
    this.currentViewIdValue = blankViewId;
    this.currentViewDataValue = data;
  }

  currentColorValueChanged(value, previousValue) {
    if (value == '' || value === previousValue) {
      return false
    }

    setTimeout(function() {
      this.blanksSelfDesignCanvasOutlets.forEach(function(outlet) {
        outlet.updateCurrentColor(value);
      });

      this.blanksSelfDesignGalleryThumbnailOutlets.forEach(function(outlet) {
        outlet.updateCurrentColor(value);
      });
    }.bind(this), 0);
  }

  setCurrentCanvas(value) {
    setTimeout(function() {
      this.blanksSelfDesignCanvasOutlets.forEach(function(outlet) {
        if (outlet.currentViewIdValue == value) {
          this.currentCanvas = outlet;
        }
      }.bind(this));
    }.bind(this), 0);
  }

  async save()
  {
    const blobs = [];
    const productDesignConfigurations = [];

    this.blanksSelfDesignCanvasOutlets.forEach(function(outlet) {
      let dt = outlet.canvas.toDataURL({
        format: 'png',
        quality: 1.0
      });
      let blob = this.dataURItoBlob(dt);
      blobs.push(blob);

      outlet.canvas.getObjects().forEach(function(obj) {
        productDesignConfigurations.push(obj.get('giftshop_attributes'));
      });
    }.bind(this));

    const formData = this.obj2FormData({
      product_design: {
        color: this.currentColorValue,
        product_design_configurations_attributes: productDesignConfigurations
      }
    });

    blobs.forEach(function(blob, index) {
      formData.append('product_design[product_design_images_attributes][][image]', blob), `image${index + 1}.png`;
    });


    const request = new FetchRequest(
      'post',
      this.saveFormTarget.action,
      {
        body: formData
      }
    );

    this.submitButtonTarget.querySelector('.loading_component').classList.remove('hidden');
    this.submitButtonTarget.querySelector('.phosphor-icon').classList.add('hidden');

    const response = await request.perform()
    if (response.ok) {
      const body = await response.text
    }
    this.submitButtonTarget.querySelector('.loading_component').classList.add('hidden');
    this.submitButtonTarget.querySelector('.phosphor-icon').classList.remove('hidden');

    if (response.redirected) {
      // Turbo.visit(new URL(response.response.url.toString(), document.baseURI))
    }
  }

  obj2FormData(obj, formData = new FormData()){
    this.formData = formData;

    this.createFormData = function(obj, subKeyStr = ''){
        for(let i in obj){
            let value          = obj[i];
            let subKeyStrTrans = subKeyStr ? subKeyStr + '[' + i + ']' : i;

            if(typeof(value) === 'string' || typeof(value) === 'number'){

                this.formData.append(subKeyStrTrans, value);

            } else if(typeof(value) === 'object'){

                this.createFormData(value, subKeyStrTrans);

            }
        }
    }

    this.createFormData(obj);

    return this.formData;
}

  dataURItoBlob(dataURI) {
      // convert base64/URLEncoded data component to raw binary data held in a string
      var byteString;
      if (dataURI.split(',')[0].indexOf('base64') >= 0)
          byteString = atob(dataURI.split(',')[1]);
      else
          byteString = unescape(dataURI.split(',')[1]);

      // separate out the mime component
      var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

      // write the bytes of the string to a typed array
      var ia = new Uint8Array(byteString.length);
      for (var i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
      }

      return new Blob([ia], {type:mimeString});
  }

  setCurrentThumbnail(value) {
    setTimeout(function() {
      this.blanksSelfDesignGalleryThumbnailOutlets.forEach(function(outlet) {
        outlet.updateActiveState(value);
      });

      this.blanksSelfDesignCanvasOutlets.forEach(function(outlet) {
        outlet.showCanvas(value);
      });
    }.bind(this), 0);
  }

  blanksSelfDesignCanvasOutletConnected(outlet, element) {
    if (!this.currentCanvas) {
      this.currentCanvas = outlet
    }
  }
}