import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    blankViewId: String,
    colorImages: Object, // All images for a blank
    currentBlankViewData: Object, // Current color data
    currentColor: String
  }

  static outlets = [
    "blanks--self-design--gallery",
    "blanks--self-design--canvas"
  ]

  connect() {
    this.currentColorValue = this.blanksSelfDesignGalleryOutlet.currentColorValue;
    this.colorImagesValue = this.blanksSelfDesignGalleryOutlet.colorImagesValue;
  }

  currentColorValueChanged(value, previousValue) {
    if (Object.keys(value).length == 0) {
      return false;
    }

    this.currentBlankViewDataValue = this.colorImagesValue[this.currentColorValue][this.blankViewIdValue];
  }

  currentBlankViewDataValueChanged(value, previousValue) {
    if (Object.keys(value).length == 0) {
      return false;
    }

    this.element.firstElementChild.src = this.currentBlankViewDataValue.thumbnail;
  }

  updateCurrentViewId() {
    this.blanksSelfDesignGalleryOutlet.updateCurrentViewId(this.currentBlankViewDataValue, this.blankViewIdValue);
  }

  updateCurrentColor(color) {
    this.currentColorValue = color;
  }

  updateActiveState(blankViewId) {
    if (blankViewId == this.blankViewIdValue) {
      if (!this.element.classList.contains("border-accent")) {
        this.element.classList.remove("border-black");
        this.element.classList.add("border-accent");
      }
      this.blanksSelfDesignGalleryOutlet.updateCurrentViewId(this.currentBlankViewDataValue, this.blankViewIdValue);
    } else {
      this.element.classList.remove("border-accent");
      this.element.classList.add("border-black");
    }
  }
}
