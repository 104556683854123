import { Controller } from '@hotwired/stimulus'
import { useIntersection } from 'stimulus-use'

export default class extends Controller {

  static targets = [ "img" ];

  connect() {
    useIntersection(this)
    this.element.classList.add('opacity-0', 'transition-opacity', 'duration-500');
  }

  appear(entry) {
    this.element.classList.add('opacity-100');
  }

}