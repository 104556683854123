import { fabric } from "fabric"
import { dispatch } from "../helpers.js"
import "./elements/imagebox.js"
import "./elements/textbox.js"
import { GIFTSHOP_OBJECT_TYPES, BLANK_OBJECT_ID } from "./constants.js"
import { applyBlendColorFilter } from "../design_editor/filters.js"
import { removeGuideLines, checkAlignmentAndSnapping } from "./snapping.js"

// TODO: Remove once resizing is available
const CANVAS_DEFAULT_WIDTH = 800
const CANVAS_DEFAULT_HEIGHT = 800

// TODO: Revert back to 900x900 zooming and paning is available
const BLANK_AREA_WIDTH = 600
const BLANK_AREA_HEIGHT = 600

// Snapping threshold
const snappingThreshold = 10;

const SharedCanvasMixin = {

  initializeShared: function() {
    this.blankArea = new fabric.Rect({
      width: BLANK_AREA_WIDTH,
      height: BLANK_AREA_HEIGHT,
      strokeWidth: 0,
      excludeFromExport: true
    });
    this.clipPath = this.blankArea;
    this.viewportCenterObject(this.blankArea);
  },

  setBackgroundImage: function(imageUrl, color, callback) {
    fabric.Image.fromURL(imageUrl, (image) => {
      image.scaleToWidth(BLANK_AREA_WIDTH);
      image.set({
        id: BLANK_OBJECT_ID,
        lockMovementX: true,
        lockMovementY: true,
        lockRotation: true,
        lockScalingX: true,
        lockScalingY: true,
        hasControls: true,
        selectable: true,
        evented: true,
        excludeFromExport: true
      })

      // Center the background image in the blank area
      image.set({
        top: this.blankArea.top,
        left: this.blankArea.left
      })

      if (color) {
        applyBlendColorFilter(color, image);
      }
      // Make sure the background image is at the bottom of the stack
      this.insertAt(image, 0)
      if (typeof callback === 'function') {
        callback(image);
      }
    }, { crossOrigin: "anonymous" })
  },
};

const Canvas = fabric.util.createClass(fabric.Canvas, {
  initialize: function(delegate, element, options = {}) {
    this.delegate = delegate
    this.canvasElement = element
    const initOptions = {
      width: CANVAS_DEFAULT_WIDTH,
      height: CANVAS_DEFAULT_HEIGHT,
      controlsAboveOverlay: true,
      preserveObjectStacking: true,
      ...options
    }
    this.callSuper("initialize", element, initOptions)
    this.initializeShared();
    this.on({
      "selection:cleared": this.selectionUpdated,
      "selection:created": this.selectionUpdated,
      "selection:updated": this.selectionUpdated,
      "object:moving": (e) => {
        var obj = e.target;
        this.clearSnapping();
        checkAlignmentAndSnapping(obj, this, this.blankArea, snappingThreshold);
        dispatch("objectMoving", {
          target: this.canvasElement,
          detail: { position: { x: Math.round(obj.left), y: Math.round(obj.top) } },
        });
      }
    })
  },

  selectionUpdated: function(event) {
    const selectedElements = this.getActiveObjects()
    this.delegate.selectionUpdated(selectedElements)
    this.clearSnapping()
  },

  clearSnapping: function() {
    removeGuideLines(this);
  },

  setBackgroundColor: function(color) {
    const image = this.findObject(BLANK_OBJECT_ID);
    this.set({blankColor: color});
    applyBlendColorFilter(color, image);
    this.renderAll();
  },

  resize: function(dimensions) {
    this.setDimensions(dimensions)
    const canvasCenter = this.getCenter()
    const blankAreaCenter = this.blankArea.getCenterPoint()
    const topLeftPoint = new fabric.Point(-(canvasCenter.left - blankAreaCenter.x), -(canvasCenter.top - blankAreaCenter.y))
    this.absolutePan(topLeftPoint)
    this.renderAll()
  },

  selectObject: function(objectId) {
    const object = this.findObject(objectId)

    if (object) {
      this.setActiveObject(object)
      this.renderAll()
    }
  },

  removeObject: function(objectId){
    const object = this.findObject(objectId)
    if (object) {
      // TODO: We might want to override canvas#remove at some point to automatically take into account objects
      // that track more than one graphical element through `elementsToRender()`
      this.remove(...object.elementsToRender())
      dispatch("objectRemoved", { target: this.canvasElement })
    }
  },

  createImage: function(file, callback) {
    const localURL = URL.createObjectURL(file)

    fabric.Image.fromURL(localURL, (imageInstance) => {
      const imageBox = new fabric.GImagebox(imageInstance, this.blankArea)
      this.add(...imageBox.elementsToRender())
      this.setActiveObject(imageBox)
      dispatch("objectAdded", { target: this.canvasElement })
      callback(imageBox)
    }, { crossOrigin: "anonymous" })
  },

  createText: function() {
    const textBox = new fabric.GTextbox("Text", this.blankArea, {}, this)
    this.add(textBox)
    dispatch("objectAdded", { target: this.canvasElement })
  },

  imageRepresentation: function() {
    const { width, height, top, left } = this.blankArea
    return this.toDataURL({ format: "png", width: width, height: height, top: top, left: left, enableRetinaScaling: true })
  },

  findObject: function(objectId) {
    return this.getObjects().find(object => object.id === objectId)
  },

  getGiftShopObjects: function(types = GIFTSHOP_OBJECT_TYPES) {
    return this.getObjects().filter(object => types.includes(object.type))
  },

})
fabric.util.object.extend(Canvas.prototype, SharedCanvasMixin);

Canvas.initWithData = function(delegate, canvasElement, canvasData, options, callback) {
  const canvas = new Canvas(delegate, canvasElement, options)

  // Handle any initialization needed once the canvas is initialized
  callback(canvas)

  if (canvasData.objects && canvasData.objects.length > 0) {
    const blankArea = canvasData.blankArea

    const oldDesignOrigin = new fabric.Point(blankArea.left, blankArea.top)
    const newDesignOrigin = new fabric.Point(canvas.blankArea.left, canvas.blankArea.top)

    canvasData.objects.forEach(element => {
      // check if object type is in GIFTSHOP_OBJECT_TYPES
      // if not, don't add it to the canvas
      if (GIFTSHOP_OBJECT_TYPES.includes(element.type)) {
        const objectType = fabric.util.getKlass(element.type)
        objectType.fromObject(element, oldDesignOrigin, newDesignOrigin, canvas.blankArea, (object) => {
          canvas.add(...object.elementsToRender())
          dispatch("objectAdded", { target: canvasElement })
        })
      }
    })
  }

  canvas.set({blankColor: canvasData.blankColor})
  canvas.setBackgroundImage(options.visualUrl, canvasData.blankColor);
  canvas.renderAll();
  return canvas
}

const StaticCanvas = fabric.util.createClass(fabric.StaticCanvas, {
  initialize: function(element, options = {}) {
    const initOptions = {
      width: CANVAS_DEFAULT_WIDTH,
      height: CANVAS_DEFAULT_HEIGHT,
      preserveObjectStacking: true,
      ...options
    }
    this.callSuper("initialize", element, initOptions);
    this.initializeShared(); // Call shared initialization
  },
});
fabric.util.object.extend(StaticCanvas.prototype, SharedCanvasMixin);

StaticCanvas.initWithData = function(canvasData, callback) {
  const canvas = new StaticCanvas(null);  
  if (canvasData.objects) {
    const blankArea = canvasData.blankArea
    const oldDesignOrigin = new fabric.Point(blankArea.left, blankArea.top)
    const newDesignOrigin = new fabric.Point(canvas.blankArea.left, canvas.blankArea.top)
    canvasData.objects.forEach(element => {
      if (GIFTSHOP_OBJECT_TYPES.includes(element.type)) {
        const objectType = fabric.util.getKlass(element.type)
        objectType.fromObject(element, oldDesignOrigin, newDesignOrigin, canvas.blankArea, (object) => {
          canvas.add(...object.elementsToRender())
        })
      }
    })
  }
  canvas.setBackgroundImage(canvasData.blankUrl, canvasData.blankColor, () => {
    canvas.renderAll();
    callback(canvas);
  });
}

export { Canvas, StaticCanvas }
