import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["quantityInput", "updateForm"];
  
  connect() {
  }

  handleQuantityUpdate() {
    clearTimeout(this.delayedSubmitCall)

    this.delayedSubmitCall = setTimeout(this.submit.bind(this), 300)
  }

  submit() {
    if (this.quantityInputTarget.value) {
      this.updateFormTarget.submit();
    }
  }
}
