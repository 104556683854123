import { GIFTSHOP_OBJECT_TYPES } from "./constants"

export default class ObjectList {
  constructor(canvas, container) {
    this.container = container
    this.canvas = canvas
    this.selectedElements = []
    this.render()
  }

  render() {
    this.container.innerHTML = ""
    this.container.append(
      ...this.objects.map(this.createObjectElement.bind(this))
    )
  }

  createObjectElement(object) {
    const instance = this.template.content.cloneNode(true)
    const listItem = instance.querySelector("li")
    const title = instance.querySelector("h4")

    listItem.dataset.objectId = object.id
    title.textContent = object.name

    if (this.selectedElements.find(element => element.id === object.id)) {
      listItem.classList.add("bg-gray-100")
    }

    return listItem
  }

  setSelection(selectedElements) {
    this.selectedElements = selectedElements
    this.render()
  }

  get listEntries() {
    return Array.from(this.container.querySelectorAll("li"))
  }

  get objects() {
    return this.canvas.getGiftShopObjects()
  }

  get template() {
    return document.getElementById("object-template")
  }
}
