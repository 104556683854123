export function drawGuideLine(canvas, isHorizontal, position, color="#FF5100") {
  var points = isHorizontal ? [0, position, canvas.width, position] : [position, 0, position, canvas.height];
  var line = new fabric.Line(points, {
    stroke: color,
    selectable: false,
    evented: false,
    excludeFromExport: true,
  });
  line.set('isGuideline', true)
  canvas.add(line);
}

export function removeGuideLines(canvas) {
  var lines = canvas.getObjects('line').filter(line => line.isGuideline);
  lines.forEach(line => canvas.remove(line));
}

export function relativePosition(origin, position, size) {
  if (origin === 'left' || origin === 'top') {
    return position;
  }else if (origin === 'center') {
    return position + size / 2;
  }else if (origin === 'right' || origin === 'bottom') {
    return position + size;
  }else{
    return position;
  }
}

export function checkAlignmentAndSnapping(movingObj, canvas, canvasArea=null, snappingThreshold=10) {
  var isAlignedX = false, isAlignedY = false;

  var movingObjCenter = movingObj.getCenterPoint();

  // check alignment with canvas blank area
  // Get center of the blank area
  if (canvasArea) {
    var areaCenter = canvasArea.getCenterPoint();
    
    // Check and draw horizontal center alignment with blank area
    if (Math.abs(movingObjCenter.y - areaCenter.y) < snappingThreshold) {
      drawGuideLine(canvas, true, areaCenter.y);
      let top = areaCenter.y - movingObj.getScaledHeight() / 2;
      top = relativePosition(
        movingObj.originY,
        top,
        movingObj.getScaledHeight()
      );
      movingObj.set('top', top);
      isAlignedY = true;
    }
  
    // Check and draw vertical center alignment with blank area
    if (Math.abs(movingObjCenter.x - areaCenter.x) < snappingThreshold) {
      drawGuideLine(canvas, false, areaCenter.x);
      let left = areaCenter.x - movingObj.getScaledWidth() / 2;
      left = relativePosition(
        movingObj.originX,
        left,
        movingObj.getScaledWidth()
      );
      movingObj.set('left', left);
      isAlignedX = true;
    }
  }

  // check if if canvas has getGiftShopObjects method defined
  if (canvas.getGiftShopObjects) {
    let lineColor = "#0284c7"
    canvas.getGiftShopObjects().forEach(obj => {
      if (obj === movingObj || obj.isGuideline) return;

      var objCenter = obj.getCenterPoint();
      console.log("objCenter", objCenter);

      // Check and draw horizontal center alignment
      if (Math.abs(movingObjCenter.y - objCenter.y) < snappingThreshold) {
        drawGuideLine(canvas, true, objCenter.y, lineColor);
        let top = objCenter.y - movingObj.getScaledHeight() / 2;
        top = relativePosition(
          movingObj.originY,
          top,
          movingObj.getScaledHeight()
        );
        movingObj.set('top', top);
        isAlignedY = true;
      }

      // Check and draw vertical center alignment
      if (Math.abs(movingObjCenter.x - objCenter.x) < snappingThreshold) {
        drawGuideLine(canvas, false, objCenter.x, lineColor);
        let left = objCenter.x - movingObj.getScaledWidth() / 2;
        left = relativePosition(
          movingObj.originX,
          left,
          movingObj.getScaledWidth()
        );
        movingObj.set('left', left);
        isAlignedX = true;
      }

      // Horizontal edge alignment (top edges)
      if (Math.abs(movingObj.top - obj.top) < snappingThreshold) {
        drawGuideLine(canvas, true, obj.top, lineColor);
        movingObj.set('top', obj.top);
        isAlignedY = true;
      }

      // Horizontal edge alignment (bottom edges)
      if (Math.abs((movingObj.top + movingObj.getScaledHeight()) - (obj.top + obj.getScaledHeight())) < snappingThreshold) {
        drawGuideLine(canvas, true, obj.top + obj.getScaledHeight());
        movingObj.set('top', obj.top + obj.getScaledHeight() - movingObj.getScaledHeight());
        isAlignedY = true;
      }

      // Vertical edge alignment (left edges)
      if (Math.abs(movingObj.left - obj.left) < snappingThreshold) {
        drawGuideLine(canvas, false, obj.left, color=lineColor);
        movingObj.set('left', obj.left);
        isAlignedX = true;
      }

      // Vertical edge alignment (right edges)
      if (Math.abs((movingObj.left + movingObj.getScaledWidth()) - (obj.left + obj.getScaledWidth())) < snappingThreshold) {
        drawGuideLine(canvas, false, obj.left + obj.getScaledWidth(), lineColor);
        movingObj.set('left', obj.left + obj.getScaledWidth() - movingObj.getScaledWidth());
        isAlignedX = true;
      }
    });
  }

  if (!isAlignedX || !isAlignedY) {
    movingObj.setCoords();
  }
}
