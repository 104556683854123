import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "form"]
  static values = {
    types: Array
  }

  connect() {
    this.selectedTypes = this.typesValue
    this.updateUI()
  }

  toggleType(event) {
    const button = event.target
    event.preventDefault();
    const type = button.dataset.type
    if (this.selectedTypes.includes(type)) {
      let index = this.selectedTypes.indexOf(type);
      this.selectedTypes.splice(index, 1);
    } else {
      this.selectedTypes.push(type)
    }
    this.updateUI();
    this.formTarget.requestSubmit()
  }

  updateUI() {
    this.inputTarget.value = this.selectedTypes
    this.element.querySelectorAll("button").forEach(button => {
      const type = button.dataset.type
      if (this.selectedTypes.includes(type)) {
        button.classList.add("text-accent")
      } else {
        button.classList.remove("text-accent")
      }
    })
  }
}
