import { Controller } from "@hotwired/stimulus"
import { patch, destroy } from "@rails/request.js"
import "../design_editor/elements/imagebox.js"
import "../design_editor/elements/textlayer.js"
import "../design_editor/elements/curved_text.js"
import { fabric } from "fabric"
import { removeGuideLines, checkAlignmentAndSnapping } from "../design_editor/snapping.js"

let debounce = require("lodash/debounce");

const CANVAS_WIDTH = 600
const CANVAS_HEIGHT = 600
const SNAPPING_THRESHOLD = 5;

export default class extends Controller {

  static targets = [
    "canvas",
    "layerControls",
  ]

  static values = {
    artwork: Object,
    layers: Array,
    layerId: Number,
  }

  connect() {
    this.initialized = false;
    this.initializeCanvas(() => {
      this.drawLayers();
    });

    this.syncLayer = debounce(this.syncLayer, 100);
    this.initialized = true;
  }

  initializeCanvas(callback) {
    this.canvas = new fabric.Canvas(this.canvasTarget, {
      selection: true,
      backgroundColor: "white",
      width: CANVAS_WIDTH,
      height: CANVAS_HEIGHT,
      controlsAboveOverlay: true,
      preserveObjectStacking: true,
    })
    this.canvas.on(
      {
        "selection:cleared": this.selectionUpdated.bind(this),
        "selection:created": this.selectionUpdated.bind(this),
        "selection:updated": this.selectionUpdated.bind(this),
        "object:moving": this.objectMoved.bind(this),
        "object:modified": this.objectModified.bind(this),
        "object:scaling": this.objectScaled.bind(this),
      }
    );

    this.canvas.getGiftShopObjects = this.getGiftShopObjects;
    callback();
  }

  drawLayers() {
    if (this.layersValue) {
      this.layersValue.forEach(layer => {
        if (layer.layer_type === "image") {
          this.addImageLayer(layer);
        } else if (layer.layer_type === "text") {
          this.addTextLayer(layer);
        } else if (layer.layer_type === "shape") {
          this.addShapeLayer(layer);
        } else if (layer.layer_type === "icon") {
          this.addImageLayer(layer);
        }
      });
    }
    this.canvas.setActiveObject(
      this.canvas.getObjects().find((object) => object.id === this.layerIdValue)
    );
    this.canvas.renderAll();
  }

  addImageLayer(layer) {
    fabric.Image.fromURL(layer.image_url, (image) => {
      const options = {
        id: layer.id,
        layer_type: layer.layer_type,
        uuid: layer.uuid,
        originX: layer.origin_x,
        originY: layer.origin_y,
        top: layer.y * CANVAS_HEIGHT,
        left: layer.x * CANVAS_WIDTH,
        width: layer.width * CANVAS_WIDTH,
        height: layer.height * CANVAS_HEIGHT
      };
      const imageBox = new fabric.GImagebox(image, options);
      this.canvas.add(...imageBox.elementsToRender())
      imageBox.elementsToRender()[0].moveTo(layer.position);
    });
  }

  addTextLayer(layer) {
    const curved = layer.curved_properties.curved === '1';
    const options = {
      uuid: layer.uuid,
      layer_type: layer.layer_type,
      fontFamily: layer.font,
      fill: layer.color,
      id: layer.id,
      text: layer.text,
      textAlign: layer.align,
      left: layer.x * CANVAS_WIDTH,
      top: layer.y * CANVAS_HEIGHT,
      originX: layer.origin_x,
      originY: layer.origin_y,
      width: layer.width * CANVAS_WIDTH,
      height: layer.height * CANVAS_HEIGHT,
      curved: curved,
      curveRadius: layer.curved_properties.curved_radius * layer.width * CANVAS_WIDTH / 2,
      curveReverse: layer.curved_properties.reverse === '1',
    }
    var textLayer = new fabric.GTextlayer(options); 
    this.canvas.add(...textLayer.elementsToRender());
    textLayer.elementsToRender()[0].moveTo(layer.position);
  }

  addShapeLayer(layer) {
    const options = {
      id: layer.id,
      layer_type: layer.layer_type,
      uuid: layer.uuid,
      left: layer.x * CANVAS_WIDTH,
      top: layer.y * CANVAS_HEIGHT,
      width: layer.width * CANVAS_WIDTH,
      height: layer.height * CANVAS_HEIGHT,
      fill: layer.color,
      stroke: layer.stroke,
      strokeWidth: layer.stroke_width
    };
    if (layer.shape_type === "circle") {
      var shape = new fabric.Circle({
        radius: Math.min(layer.width, layer.height) * CANVAS_WIDTH / 2,
        ...options}
      );
    }
    else if (layer.shape_type === "rect") {
      var shape = new fabric.Rect(options);
    }
    else{
      var shape = new fabric.Rect(options);
    }
    this.addElementToCanvas(shape);
  }

  addElementToCanvas(element) {
    this.canvas.add(element);
  }

  selectionUpdated(e){
    removeGuideLines(this.canvas);
    if (this.initialized === true){
      const object = this.canvas.getActiveObject();
      const layer_controls_frame = document.getElementById("layer_controls");
      const layers_frame = document.getElementById("layers");
      if (object) {
        layer_controls_frame.hidden = false;
        layer_controls_frame.setAttribute("src", `/artwork_layers/${object.id}/controls`);
        layers_frame.setAttribute("src", `/artworks/${this.artworkValue.id}/artwork_layers?layer_id=${object.id}&from=design_studio`);
      }else{
        layer_controls_frame.hidden = true;
        layers_frame.setAttribute("src", `/artworks/${this.artworkValue.id}/artwork_layers?from=design_studio`);
      }
    }
  }

  objectScaled(e){
    const object = e.target
  }

  objectMoved(e){
    var obj = e.target;
    removeGuideLines(this.canvas);
    checkAlignmentAndSnapping(obj, this.canvas, this.canvas, SNAPPING_THRESHOLD);
    this.syncLayer(e.target);
  }

  objectModified(e){
    this.syncLayer(e.target);
  }

  async syncLayer(object){
    let payload = {
      responseKind: "turbo-stream",
      body: {
        from: "canvas",
        artwork_layer: {
          x: parseFloat(object.left / CANVAS_WIDTH).toFixed(2),
          y: parseFloat(object.top / CANVAS_HEIGHT).toFixed(2),
          text: object.text,
          width: parseFloat(object.getScaledWidth() / CANVAS_WIDTH).toFixed(2),
          height: parseFloat(object.getScaledHeight() / CANVAS_HEIGHT).toFixed(2),
          fabric_object: object.toObject(),
        }
      }
    }
    let request = patch(`/artwork_layers/${object.id}`, payload);
    request.then((response) => {
      if (response.ok) {}
    });
  }

  async removeLayer(object){
    let payload = {
      responseKind: "turbo-stream",
    }
    let request = destroy(`/artwork_layers/${object.id}`, payload);
    request.then((response) => {
      if (response.ok) {
        this.canvas.remove(object);
      }
    });
  }

  artworkValueChanged() {
  }

  layersValueChanged(){
  }

  getGiftShopObjects(){
    let objects = this.getObjects();
    objects = objects.filter((object) => object.uuid);
    return objects;
  }

  handleKeydown(event) {
    if (event.target.tagName === 'TEXTAREA' || event.target.tagName === 'INPUT') {
      return;
    }
    if (event.key === 'Backspace' || event.keyCode === 8 || event.key === 'Delete' || event.keyCode === 46) {
      const activeObject = this.canvas.getActiveObject();
      if (activeObject) {
        if ( activeObject.layer_type !== 'text' || !activeObject.isEditing ) {
          event.preventDefault();
          this.removeLayer(activeObject);
        }
      }
    }
  }


}
