import { fabric } from "fabric"
import { nanoid } from "nanoid"
import { numberHandler } from "../parameter_handlers.js"

fabric.GTextbox = fabric.util.createClass(fabric.Textbox, {
  type: "GTextbox",

  initialize: function(text, printingArea, options = {}, canvas) {
    this.id = nanoid();
    this.printingArea = printingArea;
    this.name = fabric.GTextbox.defaultName

    let defaultOptions = {
      width: 600, // Fallback width
      textAlign: 'center', // Fallback alignment
      splitByGrapheme: true
    };

    if (canvas && canvas.blankArea) {
      const blankArea = canvas.blankArea;
      defaultOptions.width = blankArea.width;
      defaultOptions.left = blankArea.left; // Center horizontally
      defaultOptions.top = blankArea.top + blankArea.height / 3;
      defaultOptions.textAlign = 'center';
    }

    const mergedOptions = Object.assign({}, defaultOptions, options);
    this.callSuper("initialize", text, mergedOptions);
  },

  parameterUpdated() {},

  elementsToRender: function() {
    return [this]
  },

  toObject: function() {
    return fabric.util.object.extend(this.callSuper("toObject"), {
      id: this.get("id"),
      name: this.get("name"),
    })
  }
})

fabric.util.object.extend(fabric.GTextbox, {
  defaultName: "Text",

  fromObject: function(object, oldOrigin, newOrigin, printingArea, callback) {
    const { text } = object
    delete object.text

    const horizontalDifference = newOrigin.x - oldOrigin.x
    const verticalDifference = newOrigin.y - oldOrigin.y

    object.left += horizontalDifference
    object.top += verticalDifference

    callback(new fabric.GTextbox(text, printingArea, object))
  },

  parameterHandlers: {
    left: numberHandler
  }
})
