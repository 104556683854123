import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"
import { get } from '@rails/request.js'

// Connects to data-controller="select--tags"
export default class extends Controller {
  static values = { url: String, default: String }

  connect() {
    var config = {
      valueField: 'value',
      labelField: 'text',
      maxItems: 1,
      create: false,
      load: (q, callback) => this.search(q, callback)
    }
    var control = new TomSelect(this.element, config)
    var defaultCategory = JSON.parse(this.defaultValue);
    control.addOption({value: defaultCategory.id, text: defaultCategory.name});
    control.addItem(defaultCategory.id);
  }

  async search(q, callback) {
    const response = await get(this.urlValue, {
      query: { q: q },
      responseKind: 'json'
    })

    if (response.ok) {
      const list = await response.json
      callback(list)
    } else {
      callback()
    }
  }

}