import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'


export default class extends Controller {

  static targets = ["turboFrame"]
  static values = {
    viewed: Number,
    delay: Number
  }

  connect() {    
    //setTimeout(() => this.showCountrySelector(), this.delayValue)
  }

  showCountrySelector(){
    if (this.viewedValue !== 1) {
      get('/country_selector', { responseKind: 'turbo-stream' })
    }
  }

}
