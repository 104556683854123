import { Controller } from '@hotwired/stimulus'
import { useIntersection } from 'stimulus-use'

export default class extends Controller {
  static targets = [ "mode", "switchText", "modeText" ]

  static values = {
    mode: { type: String, default: 'month' },
    modeText: { type: String, default: 'Monthly' }
  }

  connect() {}

  toggle(event) {
    let target = event.currentTarget
    this.modeValue = target.checked ? "year" : "month"
    this.modeTextValue = target.checked ? "Yearly" : "Monthly"

    this.checkCheckboxes(target.checked)
  }

  checkCheckboxes(is_checked) {
    this.modeTargets.forEach((element) => {
      element.checked = is_checked
    });
  }


  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  modeValueChanged() {
    console.log("modeValueChanged");
    this.element.querySelectorAll(`input[type="hidden"][name="subscription[lookup_key]"]`).forEach((element) => {
      element.value = element.value.replace(/(year|month)/, this.modeValue)
    });

    this.modeTextTargets.forEach((element) => {
      element.textContent = this.capitalizeFirstLetter(this.modeTextValue)
    });

    this.switchTextTargets.forEach((element) => {
      element.textContent = element.dataset[this.modeValue + "Text"]
    });
  }
}