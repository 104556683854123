import { Controller } from '@hotwired/stimulus'
import { useIntersection } from 'stimulus-use'

export default class extends Controller {

  connect() {
    useIntersection(this)
    if (!this.isVisible()) {
      this.element.classList.add('opacity-0', 'transition-opacity', 'duration-300');
    }
  }

  appear() {
    this.element.classList.add('opacity-100');
  }

}