import { fabric } from 'fabric';

let drawCirclePath = (r, reverse) => {
  if (reverse){
    return `M 0 0 a ${r},${r} 0 0 0 ${r * 2},0`
  }else {
    return `M 0 0 a ${r},${r} 0 0 1 ${r * 2},0`
  }
};

fabric.IText.prototype.setCurvedTextPath = function () {
  if (this.curved) {
    let path = new fabric.Path(drawCirclePath(this.curveRadius, this.curveReverse), {
      visible: true,
      fill: "transparent",
      stroke: "red",
      strokeWidth: 0.5,
    });
    this.set('path', path);
    this.setCurvedTextPosition();
  }
};

fabric.IText.prototype.setCurvedTextPosition = function () {
  if (this.curved && this.path) {
    this.pathSide = this.curveReverse ? 'right' : 'left';
    const offset = this.curveReverse ? Math.PI * this.curveRadius / 2 : Math.PI * this.curveRadius * 2 * 0.25;
    this.pathStartOffset = offset - this.calcTextWidth() / 2;
    this.pathAlign = 'center';
    this.textAlign = 'left'
  }
};