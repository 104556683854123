import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"

// Connects to data-controller="select--colors"
export default class extends Controller {

  
  connect() {

    var config = {
      plugins: [],
      create: true,
      allowEmptyOption: true,
      maxItems: 1,
      render:{
        option: function(data, escape) {
          return `
            <div class="flex flex-row items-center gap-2">
              <div class="h-6 w-6 border-2 border-stone-400" style="background-color: ${data.value};"></div>
              <div>${escape(data.text)} (${escape(data.value)})</div>
            </div>
          `
        },
        item: function(data, escape) {
          return `
            <div class="flex flex-row items-center pr-4" style="display: inline-flex">
              <div class="h-6 w-6 border-2 border-stone-400 mr-2" style="background-color: ${data.value};"></div>
              <div class="">${escape(data.text)}</div>
            </div>
          `
        },
      }
    }
    new TomSelect(this.element, config)

  }

}