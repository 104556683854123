import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  submitForm(event) {
    const { files, form } = event.target

    if (files.length > 0 && event.target.form) {
      form.requestSubmit()
    }
  }

  initializeUpload(event) {
    const { detail } = event
    const { id, file } = detail

    const container = document.getElementById("direct-upload-container")

    container.insertAdjacentHTML("afterbegin", `
      <div id="direct-upload-${id}" class="bg-white rounded shadow px-4 py-3">
        <div class="relative h-2">
          <div class="bg-gray-300 rounded-full h-2 w-full top-0 absolute"></div>
          <div id="direct-upload-progress-${id}" class="absolute bg-accent rounded-full h-2 w-full top-0" style="width: 0"></div>
        </div>
  
        <div class="mt-2 direct-upload__filename"></div>
      </div>
    `)

    container.firstElementChild.querySelector(`.direct-upload__filename`).textContent = file.name
  }

  updateUploadProgress(event) {
    const { id, progress } = event.detail
    const progressElement = document.getElementById(`direct-upload-progress-${id}`)
    progressElement.style.width = `${progress}%`
  }
}
