import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["iframeContainer"]

  static values = {
    signatureLink: String,
    sandbox: Boolean
  }

  connect() {
    new Yousign({
      signatureLink: this.signatureLinkValue,
      iframeContainerId: this.iframeContainerTarget.id,
      isSandbox: this.sandboxValue
    });
  }
}
