import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static values = {
    url: String
  }
  
  goTo(e) {
    if (!e.target.href) {
      Turbo.visit(this.urlValue)
    }
  }

}
