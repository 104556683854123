import ScrollProgress from 'stimulus-scroll-progress'

export default class extends ScrollProgress {
  
  connect() {
    super.connect()
    console.log('ScrollProgressController#connect')
  }

  scroll () {
    var height = document.documentElement.scrollHeight - document.documentElement.clientHeight
    var width = (window.scrollY / height) * 100
    this.element.style.width = `${width}%`
  }
}