import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="nested-form"
export default class extends Controller {

  static targets = ["add_item", "template", "add_item1", "template1"]

  static outlets =  [ "admin--product-design--blank-select"]

  add_association(event) {
    event.preventDefault()
    var content = this.templateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, new Date().valueOf())
    this.add_itemTarget.insertAdjacentHTML('beforebegin', content)
    this.adminProductDesignBlankSelectOutlet.updateForm();
  }

  add_association1(event) {
    event.preventDefault()
    var content = this.template1Target.innerHTML.replace(/TEMPLATE_RECORD1/g, new Date().valueOf())
    this.add_item1Target.insertAdjacentHTML('beforebegin', content)
    this.adminProductDesignBlankSelectOutlet.updateForm();
  }

  remove_association(event) {
    event.preventDefault()
    let item = event.target.closest(".nested-fields")
    item.querySelector("input[name*='_destroy']").value = 1
    item.querySelector("input[required='required'], select[required='required']").removeAttribute("required")
    item.style.display = 'none'
  }

  remove_association1(event) {
    event.preventDefault()
    let item = event.target.closest(".nested-fields1")
    item.querySelector("input[name*='_destroy']").value = 1
    item.style.display = 'none'
  }
}
