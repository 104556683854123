import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["input"];
  
  connect() {
    // call focus() on the input element when the controller is connected with a timeout
    // to ensure that the element is visible when the focus() method is called
    setTimeout(() => this.focus(), 20);
  }

  focus() {
    this.inputTarget.focus();
  }
}
