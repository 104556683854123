import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static values = { fallback: String }

  initialize() {
    this.navigateBack = this.navigateBack.bind(this);
    this.element.addEventListener('click', this.navigateBack);
  }

  navigateBack(event) {
    event.preventDefault(); // Prevent default action if the element is a link
    const fallback = this.fallbackValue;
    // check if history is available
    if (window.history && window.history.back) {
      window.history.back();
    } else if (fallback) {
      window.location.href = fallback;
    }
  }
}