// Connects to data-controller="navbar-style"
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["navbar", "hero"]; // Define navbar and hero image as targets

  connect() {
    // When the page loads, check if the hero image exists
    if (this.hasHeroTarget) {
      this.initObserver(); // Initialize the observer to monitor the intersection of navbar and hero image
    } else {
      this.setSolidNavbar(); // If no hero image, default to the solid navbar
    }
  }

  // Initialize Intersection Observer to watch the intersection between navbar and hero image
  initObserver() {
    const observerOptions = {
      root: null, // We are using the viewport as the root
      threshold: [0, 1], // Observe when hero fully or partially intersects the navbar
      rootMargin: `-${this.navbarTarget.offsetHeight}px 0px 0px 0px`, // Adjust based on navbar height
    };

    // Create a new IntersectionObserver instance and observe the hero image
    this.observer = new IntersectionObserver(this.handleIntersect.bind(this), observerOptions);
    this.observer.observe(this.heroTarget); // Watch the hero image target
  }

  // When the hero image intersects with the navbar
  handleIntersect(entries) {
    entries.forEach(entry => {
      if (entry.intersectionRatio > 0) {
        // Hero image is still overlapping with the navbar
        this.setTransparentNavbar();
      } else {
        // Hero image is no longer overlapping with the navbar
        this.setSolidNavbar();
      }
    });
  }

  // Change the navbar to transparent
  setTransparentNavbar() {
    this.navbarTarget.classList.add("navbar-transparent");
    this.navbarTarget.classList.remove("navbar-solid");
  }

  // Change the navbar to solid
  setSolidNavbar() {
    this.navbarTarget.classList.add("navbar-solid");
    this.navbarTarget.classList.remove("navbar-transparent");
  }

  disconnect() {
    // Clean up the observer when the controller is disconnected
    if (this.observer) {
      this.observer.disconnect();
    }
  }
}
