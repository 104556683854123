import { DirectUpload } from "@rails/activestorage"

export default class Uploader {
  constructor(directUploadURL) {
    this.directUploadURL = directUploadURL
  }

  upload(file, callback) {
    const upload = new DirectUpload(file, this.directUploadURL)

    upload.create((error, blob) => {
      if (error) {
        console.log(error)
      } else {
        callback(blob)
      }
    })
  }
}
