import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "form"]
  static values = {
    colors: String
  }

  connect() {
  }

  handleUpload(event) {
    this.formTarget.requestSubmit();
  }

}
