import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["imageFront", "imageBack"]
  connect() {
  }

  toogleImages(event) {
    if (!this.hasImageBackTarget || !this.hasImageFrontTarget) {
        return
    }
    this.imageFrontTarget.classList.toggle("hidden")
    this.imageBackTarget.classList.toggle("hidden")
  }
}
