import { Application } from "@hotwired/stimulus"
import Turn from '@domchristie/turn'

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

// Page view animations
Turn.start()


export { application }
