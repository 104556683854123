import { Controller } from "@hotwired/stimulus"
import { camelize } from "../../helpers"

export default class extends Controller {
  static values = {
    objectId: String,
  }

  static outlets = ["design-editor"]

  connect() {
    this.autocompleteInputs()
    this.inputs.forEach(this.registerEventListener.bind(this))

    // Do not propagate keydown events to prevent otherwise
    // hitting backspace in the form input will remove the selected
    // element from the canvas
    this.element.addEventListener("keydown", this.stopPropagationOfKeydownEvents)
  }

  disconnect() {
    this.inputs.forEach(input => {
      input.removeEventListener("change", this.updateElement)
    })

    this.element.removeEventListener("keydown", this.stopPropagationOfKeydownEvents)
  }

  autocompleteInputs() {
    this.inputs.forEach(input => {
      const name = camelize(input.name)
      const type = input.type
      const value = this.object[name]

      if (!value) { return }

      switch (type) {
        case "radio":
          input.checked = input.value === value
          break
        default:
          input.value = value
      }
    })
  }

  refreshInputs() {
    this.autocompleteInputs()
  }

  registerEventListener(input) {
    input.addEventListener("change", this.updateElement.bind(this))
  }

  updateElement(event) {
    const { target } = event
    const name = target.name
    let value = target.value

    // Make sure name has always a value
    if (name === "name" && value === "") {
      value = this.object.constructor.defaultName
      target.value = value
    }

    this.designEditorOutlet.updateElement(this.objectIdValue, camelize(name), value)
  }

  stopPropagationOfKeydownEvents(event) {
    event.stopPropagation()
  }

  get object() {
    return this.designEditorOutlet.canvas.findObject(this.objectIdValue)
  }

  get inputs() {
    return this.element.querySelectorAll("input, textarea, select")
  }
}
