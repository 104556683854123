import { Controller } from "@hotwired/stimulus"
import { fabric } from "fabric"
import { removeGuideLines, checkAlignmentAndSnapping } from "../../design_editor/snapping.js";

const CANVAS_DEFAULT_WIDTH = 600
const CANVAS_DEFAULT_HEIGHT = 600
const SNAPPING_THRESHOLD = 5;

export default class extends Controller {


  static targets = [
    "canvasElement",
    "canvasContainer",
    "placementLeft",
    "placementTop",
    "placementWidth",
    "placementHeight",
    "placementOriginX",
    "placementOriginY"
  ]

  static values = {
    placementPosition: Object,
    blankView: Object,
    blankViewUrl: String
  }

  connect() {
    this.initializeCanvas(() => {
      this.setBlankViewImage(() => {
        this.setPlacement();
      });
    });
  }


  initializeCanvas(callback) {
    this.canvas = new fabric.Canvas(this.canvasElementTarget, {
      selection: true,
      backgroundColor: "white",
      width: CANVAS_DEFAULT_WIDTH,
      height: CANVAS_DEFAULT_HEIGHT,
      controlsAboveOverlay: true,
      preserveObjectStacking: true,
      strokeWidth: 1,
    })

    // create rect for blank
    this.blankArea = new fabric.Rect({
      width: CANVAS_DEFAULT_WIDTH,
      height: CANVAS_DEFAULT_HEIGHT,
      strokeWidth: 1,
      stroke: "#FF5100",
      fill: "transparent",
      excludeFromExport: true,
      lockMovementX: true,
      lockMovementY: true,
      lockRotation: true,
      lockScalingX: true,
      lockScalingY: true,
      hasControls: false,
    });

    this.canvas.clipPath = this.blankArea;
    this.canvas.viewportCenterObject(this.blankArea);
    this.canvas.blankArea = this.blankArea;
    //this.canvas.insertAt(this.blankArea, 0);
    callback();
  }

  setBlankViewImage(callback) {
    fabric.Image.fromURL(this.blankViewUrlValue, (image) => {
      this.blankViewImagePixels = {width: image.width, height: image.height};
      image.scaleToWidth(CANVAS_DEFAULT_WIDTH);
      this.scale = image.scaleX;
      image.set({
        lockmovementx: true,
        lockmovementy: true,
        lockrotation: true,
        lockscalingx: true,
        lockscalingy: true,
        hascontrols: false,
        selectable: false,
        evented: false,
        excludefromexport: true
      })
      image.set({
        top: this.blankArea.top,
        left: this.blankArea.left
      })
      // this.canvas.insertAt(image, 0);
      this.canvas.add(image);
      this.canvas.renderAll();
      if (typeof callback === 'function') {
        callback();
      }
    }, { crossOrigin: "anonymous" })
  }


  xConvertToPixels(mm) {
    const mm_to_px = this.blankViewValue.width_mm / this.blankViewImagePixels.width;
    const pixels = parseInt(mm / mm_to_px * this.scale);
    return pixels;
  }
  yConvertToPixels(mm) {
    const mm_to_px = this.blankViewValue.height_mm / this.blankViewImagePixels.height;
    return parseInt(mm / mm_to_px * this.scale);
  }
  xConvertToMm(pixels) {
    const mm_to_px = this.blankViewValue.width_mm / this.blankViewImagePixels.width;
    return parseInt(pixels * mm_to_px / this.scale)
  }
  yConvertoMm(pixels) {
    const mm_to_px = this.blankViewValue.height_mm / this.blankViewImagePixels.height;
    return parseInt(pixels * mm_to_px / this.scale)
  }

  setPlacement() {
    // draw a rectangle for the placement
    // this rectangle is positionned according to the placement position value (top, left, width, height)
    const placement = new fabric.Rect({
      width: this.xConvertToPixels(this.placementPositionValue.max_width_mm),
      height: this.yConvertToPixels(this.placementPositionValue.max_height_mm),
      strokeWidth: 1,
      stroke: "#FF5100",
      fill: "transparent",
      left: this.xConvertToPixels(this.placementPositionValue.left_mm),
      top: this.yConvertToPixels(this.placementPositionValue.top_mm),
      selectable: true,
      evented: true,
      originX: this.placementPositionValue.originX,
      originY: this.placementPositionValue.originY,
      excludefromexport: true
    });
    this.placement = placement;
    placement.on({
      "moving": function () {
        removeGuideLines(this.canvas);
        this.placementLeftTarget.value = this.xConvertToMm(placement.left);
        this.placementTopTarget.value = this.yConvertoMm(placement.top);
        checkAlignmentAndSnapping(placement, this.canvas, this.canvas.blankArea, SNAPPING_THRESHOLD);
      }.bind(this),
      "scaling": function () {
        this.placementWidthTarget.value = this.xConvertToMm(placement.getScaledWidth());
        this.placementHeightTarget.value = this.yConvertoMm(placement.getScaledHeight());
        this.placementLeftTarget.value = this.xConvertToMm(placement.left);
        this.placementTopTarget.value = this.yConvertoMm(placement.top);
      }.bind(this),
      "mouseout": function () {
        console.log("selection cleared")
        removeGuideLines(this.canvas);
      }.bind(this),
    });
    this.canvas.add(placement);
  }

  inputChanged(event) {
    const input = event.target;
    const name = input.id
    const value = input.value
    const scale = this.placement.getObjectScaling();
    switch (name) {
      case "placementLeft":
        this.placement.set({"left": this.xConvertToPixels(value)});
        break;
      case "placementTop":
        this.placement.set({"top": this.yConvertToPixels(value)});
        break;
      case "placementWidth":
        this.placement.set({"width": this.xConvertToPixels(value)});
        break;
      case "placementHeight":
        this.placement.set({"height": this.yConvertToPixels(value)});
        break;
      case "placementOriginX":
        this.placement.set({"originX": value});
        break;
      case "placementOriginY":
        this.placement.set({"originY": value});
        break;
    }
    this.canvas.renderAll();
  }
}
