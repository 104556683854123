// app/javascript/controllers/drawer_controller.js
import { Controller } from "@hotwired/stimulus"
import { enter, leave } from "el-transition"


export default class extends Controller {

  static targets = ["content", "overlay"]
  
  connect() {
    enter(this.contentTarget)
  }

  close() {
    this.contentTarget.classList.add('translate-x-full');
    this.overlayTarget.classList.add('hidden');
    this.drawerTurboFrame.innerHTML = ''; // clear the drawer
  }

  disconnect() {
    this.close();
  }

  get drawerTurboFrame() {
    return document.querySelector("turbo-frame[id='drawer']");
  }

}
