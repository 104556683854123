import { fabric } from "fabric"
import { nanoid } from "nanoid"

fabric.GImagebox = fabric.util.createClass(fabric.Rect, {
  type: "GImagebox",

  initialize: function(imageInstance, options={}) {
    this.image = imageInstance
    this.id = nanoid()
    this.name = fabric.GImagebox.defaultName

    this.image.set({
      selectable: false,
      evented: false,
      excludeFromExport: true
    })

    this.callSuper("initialize", {
      fill: "transparent",
      ...options
    })

    this.scaleImageToFitBoundaries()
    this.on({
      "scaling": function() {
        this.scaleImageToFitBoundaries()
      }.bind(this),
      "moving": function() {
        this.centerImageInBox()
      }.bind(this)
    })
  },

  parameterUpdated() {
    this.scaleImageToFitBoundaries()
    this.centerImageInBox()
  },

  elementsToRender: function() {
    return [this, this.image]
  },

  centerImageInBox() {
    let centerPoint = this.getCenterPoint()
    this.image.setPositionByOrigin(centerPoint, 'center', 'center')
  },

  scaleImageToFitBoundaries() {
    const scaleFactor = Math.min(
      this.getScaledHeight() / this.image.getScaledHeight(),
      this.getScaledWidth() / this.image.getScaledWidth()
    )

    this.image.scaleToWidth(this.image.getScaledWidth() * scaleFactor)
    this.centerImageInBox()
  },

  toObject: function() {
    return fabric.util.object.extend(this.callSuper("toObject"), {
      id: this.get("id"),
      sgid: this.get("sgid"),
      name: this.get("name")
    })
  }
})