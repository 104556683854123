import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["source"]

  copy() {
    if ("clipboard" in navigator) {
      navigator.clipboard.writeText(this.sourceTarget.value)
    }
  }
}
