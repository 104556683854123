import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "form"]
  static values = {
    colors: Array
  }

  connect() {
    this.selectedColors = this.colorsValue
    this.updateUI()
  }

  toggleColor(event) {
    const button = event.target
    event.preventDefault();
    const color = button.dataset.color
    if (this.selectedColors.includes(color)) {
      let index = this.selectedColors.indexOf(color);
      this.selectedColors.splice(index, 1);
    } else {
      this.selectedColors.push(color)
    }
    this.updateUI();
    this.formTarget.requestSubmit()
  }

  updateUI() {
    this.inputTarget.value = this.selectedColors
    this.element.querySelectorAll("button").forEach(button => {
      const color = button.dataset.color
      if (this.selectedColors.includes(color)) {
        button.classList.add("ring-2", "ring-accent")
      } else {
        button.classList.remove("ring-2", "ring-accent")
      }
    })
  }
}
