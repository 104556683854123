import { Controller } from "@hotwired/stimulus"

const colorSelector = ".color-selector";

export default class extends Controller {

  static values = {
    colors: Array,
    currentColor: String
  }

  static outlets = [
    "blanks--self-design--gallery"
  ]

  connect() {
    // Order is important here
    this.currentColorTarget = this.getColorElements()[0];
    this.currentColorValue = this.colorsValue[0];
  }

  changeCurrentColor(event) {
    this.currentColorValue = event.target.dataset.color;
    this.currentColorTarget = event.currentTarget;
  }

  getColorElements() {
    return this.element.querySelectorAll(colorSelector);
  }

  currentColorValueChanged(value, previousValue) {
    if (value == '' || value === previousValue) {
      return false
    }
    this.blanksSelfDesignGalleryOutlet.updateCurrentColor(value);

    if (this.getColorElements().length > 0) {
      this.getColorElements().forEach(function(element) {
        element.classList.remove('color--selected');
      });
    }
    if (this.currentColorTarget) {
      this.currentColorTarget.classList.add("color--selected");
    }
  }
}
