import { fabric } from 'fabric';

export const applyBlendColorFilter = function(color, image) {
  image.filters = image.filters.filter(filter => !(filter instanceof fabric.Image.filters.BlendColor));
  if (color !== "") {
    image.filters.push(new fabric.Image.filters.BlendColor({
      color: color,
      mode: 'multiply',
      opacity: 0.2
    }));
  }
  image.applyFilters();
}