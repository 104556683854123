import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"

// Connects to data-controller="select--multi-select"
export default class extends Controller {

  static values = { default: Array }

  connect() {
    var config = {
      plugins: ['remove_button'],
      create: true,
      selectOnTab: true,
      closeAfterSelect: true,
      openOnFocus: true,
      highlight: true,
      allowEmptyOption: true,
      maxItems: null,
      items: this.defaultValue
    }
    new TomSelect(this.element, config)
  }
}