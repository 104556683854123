export function nextFrame() {
  return new Promise(requestAnimationFrame)
}

export function dispatch(
  eventName,
  {
    target = document,
    detail = {},
    bubbles = true,
    cancelable = true,
  },
  ) {
  const event = new CustomEvent(eventName, { detail, bubbles, cancelable })
  target.dispatchEvent(event)
  return event
}

export function dataURLtoBlob(dataURI) {
  const [dataPrefix, data] = dataURI.split(",")

  const mimeType = dataPrefix.match(/:(.*?);/)[1]
  const binaryString = atob(data)
  let n = binaryString.length
  const u8arr = new Uint8Array(n)

  while(n--) {
    u8arr[n] = binaryString.charCodeAt(n)
  }

  return new Blob([u8arr], { type: mimeType })
}

// Camelize strings of the form my_string into myString
export function camelize(string) {
  return string.replace(/_([a-z])/g, function (g) { return g[1].toUpperCase() })
}
