import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["darkIcon", "lightIcon", "darkText", "lightText"]

  connect() {
    this.enableDarkMode();
  }

  enableDarkMode() {
    localStorage.setItem('color-theme', 'dark');
    document.documentElement.classList.add('dark');
  }

  updateTheme() {
    if (localStorage.getItem('color-theme') === 'dark' || (!('color-theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
      document.documentElement.classList.add('dark');
      this.darkIconTarget.classList.add('hidden');
      this.lightIconTarget.classList.remove('hidden');
      this.darkTextTarget.classList.add('hidden');
      this.lightTextTarget.classList.remove('hidden');
    } else {
      document.documentElement.classList.remove('dark');
      this.darkIconTarget.classList.remove('hidden');
      this.lightIconTarget.classList.add('hidden');
      this.darkTextTarget.classList.add('hidden');
      this.lightTextTarget.classList.remove('hidden');
    }
  }

  toggleTheme() {
    // Toggle the class on the html element and switch icons visibility
    if (document.documentElement.classList.contains('dark')) {
      document.documentElement.classList.remove('dark');
      localStorage.setItem('color-theme', 'light');
      this.darkIconTarget.classList.remove('hidden');
      this.lightIconTarget.classList.add('hidden');
      this.darkTextTarget.classList.add('hidden');
      this.lightTextTarget.classList.remove('hidden');
    } else {
      document.documentElement.classList.add('dark');
      localStorage.setItem('color-theme', 'dark');
      this.darkIconTarget.classList.add('hidden');
      this.lightIconTarget.classList.remove('hidden');
      this.darkTextTarget.classList.add('hidden');
      this.lightTextTarget.classList.remove('hidden');
    }
  }
}