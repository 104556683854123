import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.updateBorders();
    window.addEventListener("resize", this.updateBorders.bind(this));

    // Create mutation observer to watch for new products
    this.observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
          this.updateBorders();
        }
      });
    });

    // Start observing the grid for changes
    this.observer.observe(this.element, {
      childList: true,
      subtree: false
    });
  }

  disconnect() {
    window.removeEventListener("resize", this.updateBorders.bind(this));
    this.observer.disconnect();
  }

  updateBorders() {
    requestAnimationFrame(() => {
      const items = Array.from(this.element.children);
      const columns = this.getColumnCount();

      items.forEach(item => {
        item.classList.remove("no-border-right", "no-border-bottom");
      });

      items.forEach((item, index) => {
        if ((index + 1) % columns === 0) {
          item.classList.add("no-border-right");
        }
      });

      const lastRowStart = items.length - (items.length % columns || columns);
      items.slice(lastRowStart).forEach(item => {
        item.classList.add("no-border-bottom");
      });
    });
  }

  getColumnCount() {
    const gridStyle = getComputedStyle(this.element);
    const columns = parseInt(gridStyle.getPropertyValue("--columns"), 10);
    return columns || 1;
  }
}
