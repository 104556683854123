import { Controller } from "@hotwired/stimulus"
import { useIntersection } from 'stimulus-use'
import { get } from "@rails/request.js";

// Connects to data-controller="pagination"
export default class extends Controller {

  static targets = ["loadMore", "loading", "lastPage"];

  static values = {
    url: String,
    page: Number,
    scroll: Boolean
  };

  connect() {
    if (!this.scrollValue) return;
    var options = { element: this.loadMoreTarget, threshold: 0, rootMargin: "1200px" }
    useIntersection(this, options)

  }

  initialize() {
  }

  appear(entry) {
    if (!this.hasLastPageTarget) {
      this._fetchNewPage();
    }
  }

  disappear(entry) {
  }

  async paginate(e) {
    if (!this.hasLastPageTarget) {
      await this._fetchNewPage();
      e.target.blur();
    }
  }

  async _fetchNewPage() {
    const url = new URL(this.urlValue);
    url.searchParams.set('page', this.pageValue)

    await get(url.toString(), {
      responseKind: 'turbo-stream'
    });

    this.pageValue += 1;
    if (this.hasLastPageTarget) {
      this.loadMoreTarget.classList.add('hidden');
    }
  }

}
