import { Controller } from "@hotwired/stimulus"
import { fabric } from "fabric"

const BACKGROUND_COLOR = "#ffffff";
const IMAGE_SCALE = 0.5;
const DELETE_ICON = "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='595.275px' height='595.275px' viewBox='200 215 230 470' xml:space='preserve'%3E%3Ccircle style='fill:%23F44336;' cx='299.76' cy='439.067' r='218.516'/%3E%3Cg%3E%3Crect x='267.162' y='307.978' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -222.6202 340.6915)' style='fill:white;' width='65.545' height='262.18'/%3E%3Crect x='266.988' y='308.153' transform='matrix(0.7071 0.7071 -0.7071 0.7071 398.3889 -83.3116)' style='fill:white;' width='65.544' height='262.179'/%3E%3C/g%3E%3C/svg%3E";
const NEW_ICON = "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIgZmlsbD0iIzAwMDAwMCIgdmlld0JveD0iMCAwIDI1NiAyNTYiPjxwYXRoIGQ9Ik0xNjUuNjYsMTAxLjY2LDEzOS4zMSwxMjhsMjYuMzUsMjYuMzRhOCw4LDAsMCwxLTExLjMyLDExLjMyTDEyOCwxMzkuMzFsLTI2LjM0LDI2LjM1YTgsOCwwLDAsMS0xMS4zMi0xMS4zMkwxMTYuNjksMTI4LDkwLjM0LDEwMS42NmE4LDgsMCwwLDEsMTEuMzItMTEuMzJMMTI4LDExNi42OWwyNi4zNC0yNi4zNWE4LDgsMCwwLDEsMTEuMzIsMTEuMzJaTTIzMiwxMjhBMTA0LDEwNCwwLDEsMSwxMjgsMjQsMTA0LjExLDEwNC4xMSwwLDAsMSwyMzIsMTI4Wm0tMTYsMGE4OCw4OCwwLDEsMC04OCw4OEE4OC4xLDg4LjEsMCwwLDAsMjE2LDEyOFoiPjwvcGF0aD48L3N2Zz4="
export default class extends Controller {

  static targets = [
    "canvasElement",
    "currentColor"
  ]

  static values = {
    currentColor: { type: String, default: "#ffffff" },
    currentViewId: { type: String, default: "" },
    viewId: { type: String, default: "" }
  }

  connect() {
    this.initializeCanvas(() => {
      this.element.classList.add("hidden");
    });
  }

  showCanvas(viewId) {
    if (viewId == this.viewIdValue) {
      this.element.classList.remove("hidden");
      this.element.classList.remove("opacity-0");
    } else {
      if (!this.element.classList.contains("hidden")) {
        this.element.classList.add("hidden");
        this.element.classList.add("opacity-0");
      }
    }
  }

  updateCurrentColor(color) {
    this.currentColorValue = color;
  }

  updateBackgroundImage(data, viewId, force = false) {
    if (!force && (!this.canvas || viewId != this.viewIdValue)) {
      return;
    }

    fabric.Image.fromURL(data.full, function(img, isError) {
      img.set({
        originX: 'left',
        originY: 'top'
      });
      img.scaleToWidth(this.canvas.getWidth(), false);
      img.scaleToHeight(this.canvas.getHeight(), false);

      this.canvas.setBackgroundImage(img, this.canvas.renderAll.bind(this.canvas), {
        left: (this.canvas.getWidth() - img.getScaledWidth()) / 2,
        top: (this.canvas.getHeight() - img.getScaledHeight()) / 2,
        crossOrigin: 'anonymous'
      });
   }.bind(this), {
      crossOrigin: 'anonymous'
   });
  }

  addArtwork(url, artworkId) {
    fabric.Image.fromURL(url, (img) => {
      const canvasCenter = this.canvas.getCenter();
      img.set({
        width: img.width,
        height: img.height,
        scaleX: IMAGE_SCALE,
        scaleY: IMAGE_SCALE,
        originX: 'center',
        originY: 'center',
        giftshop_attributes: {
          "artwork_id": artworkId,
          "blank_view_id": this.viewIdValue,
          "embellishment_technic_id": null,
          "embellishment_width": null,
        },
        left: canvasCenter.left,
        top: canvasCenter.top,
        crossOrigin: 'anonymous',
        lockUniScaling: true,
        lockScalingFlip: true,
        transparentCorners: false,
        cornerColor: 'rgba(102,153,255,0.5)',
        cornerSize: 12,
        padding: 10
      });

      // Remove middle controls
      img.setControlsVisibility({
        mt: false,
        mb: false,
        ml: false,
        mr: false
      });

      this.canvas.add(img);
      this.canvas.setActiveObject(img);
      this.canvas.renderAll();
    }, {
      crossOrigin: 'anonymous'
    });
  }

  initializeCanvas(callback) {
    this.canvas = new fabric.Canvas(this.canvasElementTarget, {
      selection: true,
      width: this.canvasElementTarget.getBoundingClientRect().width,
      height: this.canvasElementTarget.getBoundingClientRect().height,
      backgroundColor: null,  // Set to null for transparent background
      controlsAboveOverlay: true,
      preserveObjectStacking: true,
      strokeWidth: 1,
    })

    var img = document.createElement('img');
    img.src = NEW_ICON;

    fabric.Object.prototype.controls.deleteControl = new fabric.Control({
      x: 0.5,
      y: -0.5,
      offsetY: 16,
      cursorStyle: 'pointer',
      mouseUpHandler: function(eventData, transform) {
        var target = transform.target;
        var canvas = target.canvas;
            canvas.remove(target);
            canvas.requestRenderAll();
      },
      render: function(ctx, left, top, styleOverride, fabricObject) {
        var size = this.cornerSize;
        ctx.save();
        ctx.translate(left, top);
        ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
        ctx.drawImage(img, -size/2, -size/2, size, size);
        ctx.restore();
      },
      cornerSize: 24
    });


    // // create rect for blank
    // this.blankArea = new fabric.Rect({
    //   width: CANVAS_DEFAULT_WIDTH,
    //   height: CANVAS_DEFAULT_HEIGHT,
    //   strokeWidth: 1,
    //   stroke: "#FF5100",
    //   fill: "transparent",
    //   excludeFromExport: true,
    //   lockMovementX: true,
    //   lockMovementY: true,
    //   lockRotation: true,
    //   lockScalingX: true,
    //   lockScalingY: true,
    //   hasControls: false,
    // });

    // this.canvas.clipPath = this.blankArea;
    // this.canvas.viewportCenterObject(this.blankArea);
    // this.canvas.blankArea = this.blankArea;
    // //this.canvas.insertAt(this.blankArea, 0);
    callback();
  }

  setBlankViewImage(callback) {
    fabric.Image.fromURL(this.blankViewUrlValue, (image) => {
      this.blankViewImagePixels = {width: image.width, height: image.height};
      image.scaleToWidth(CANVAS_DEFAULT_WIDTH);
      this.scale = image.scaleX;
      image.set({
        lockmovementx: true,
        lockmovementy: true,
        lockrotation: true,
        lockscalingx: true,
        lockscalingy: true,
        hascontrols: false,
        selectable: false,
        evented: false,
        excludefromexport: true
      })
      image.set({
        top: this.blankArea.top,
        left: this.blankArea.left
      })
      // this.canvas.insertAt(image, 0);
      this.canvas.add(image);
      this.canvas.renderAll();
      if (typeof callback === 'function') {
        callback();
      }
    }, { crossOrigin: "anonymous" })
  }


  xConvertToPixels(mm) {
    const mm_to_px = this.blankViewValue.width_mm / this.blankViewImagePixels.width;
    const pixels = parseInt(mm / mm_to_px * this.scale);
    return pixels;
  }
  yConvertToPixels(mm) {
    const mm_to_px = this.blankViewValue.height_mm / this.blankViewImagePixels.height;
    return parseInt(mm / mm_to_px * this.scale);
  }
  xConvertToMm(pixels) {
    const mm_to_px = this.blankViewValue.width_mm / this.blankViewImagePixels.width;
    return parseInt(pixels * mm_to_px / this.scale)
  }
  yConvertoMm(pixels) {
    const mm_to_px = this.blankViewValue.height_mm / this.blankViewImagePixels.height;
    return parseInt(pixels * mm_to_px / this.scale)
  }

  setPlacement() {
    // draw a rectangle for the placement
    // this rectangle is positionned according to the placement position value (top, left, width, height)
    const placement = new fabric.Rect({
      width: 200,
      height: 200,
      strokeWidth: 1,
      stroke: "#FF5100",
      fill: "transparent",
      left: 10,
      top: 10,
      selectable: true,
      evented: true,
      originX: 0,
      originY: 0,
      excludefromexport: true
    });
    this.placement = placement;
    // placement.on({
    //   "moving": function () {
    //     removeGuideLines(this.canvas);
    //     this.placementLeftTarget.value = this.xConvertToMm(placement.left);
    //     this.placementTopTarget.value = this.yConvertoMm(placement.top);
    //     checkAlignmentAndSnapping(placement, this.canvas, this.canvas.blankArea, SNAPPING_THRESHOLD);
    //   }.bind(this),
    //   "scaling": function () {
    //     this.placementWidthTarget.value = this.xConvertToMm(placement.getScaledWidth());
    //     this.placementHeightTarget.value = this.yConvertoMm(placement.getScaledHeight());
    //     this.placementLeftTarget.value = this.xConvertToMm(placement.left);
    //     this.placementTopTarget.value = this.yConvertoMm(placement.top);
    //   }.bind(this),
    //   "mouseout": function () {
    //     console.log("selection cleared")
    //     removeGuideLines(this.canvas);
    //   }.bind(this),
    // });
    this.canvas.add(placement);
  }

  inputChanged(event) {
    const input = event.target;
    const name = input.id
    const value = input.value
    const scale = this.placement.getObjectScaling();
    switch (name) {
      case "placementLeft":
        this.placement.set({"left": this.xConvertToPixels(value)});
        break;
      case "placementTop":
        this.placement.set({"top": this.yConvertToPixels(value)});
        break;
      case "placementWidth":
        this.placement.set({"width": this.xConvertToPixels(value)});
        break;
      case "placementHeight":
        this.placement.set({"height": this.yConvertToPixels(value)});
        break;
      case "placementOriginX":
        this.placement.set({"originX": value});
        break;
      case "placementOriginY":
        this.placement.set({"originY": value});
        break;
    }
    this.canvas.renderAll();
  }
}