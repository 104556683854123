import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  toggle(event) {
    const button = event.currentTarget

    const item = button.parentElement
    item.toggleAttribute("open")
  }
}
