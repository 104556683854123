import { fabric } from "fabric"
import { nanoid } from "nanoid"
import './curved_text.js';

fabric.GTextlayer = fabric.util.createClass(fabric.Rect, {
  type: "GTextlayer",

  initialize: function(options={}) {
    this.id = nanoid()
    this.name = fabric.GTextlayer.defaultName
    this.text = new fabric.IText(options.text, {
      selectable: false,
      evented: false,
      excludeFromExport: true,
      fontFamily: options.fontFamily,
      fill: options.fill,
      textAlign: "center",
      curved: options.curved,
      curveRadius: options.width / 2,
      curveReverse: options.curveReverse,
    });
    this.text.setCurvedTextPath()

    this.callSuper("initialize", {
      fill: "transparent",
      id: options.id,
      uuid: options.uuid,
      layer_type: options.layer_type,
      left: options.left,
      top: options.top,
      originX: options.originX,
      originY: options.originY,
      width: options.width,
      height: options.height,
    })

    this.scaleToFit();

    this.on({
      "scaling": function() {
        this.scaleToFit()
      }.bind(this),
      "moving": function() {
        this.centerInBox()
      }.bind(this)
    })
  },

  elementsToRender: function() {
    return [this, this.text]
  },

  centerInBox() {
    let centerPoint = this.getCenterPoint()
    this.text.setPositionByOrigin(centerPoint, 'center', 'center')
  },

  scaleToFit() {
    const scaleFactorX = this.getScaledWidth() / this.text.getScaledWidth()
    const scaleFactorY = this.getScaledHeight() / this.text.getScaledHeight()
    if (scaleFactorX < scaleFactorY) {
      this.text.scaleToWidth(this.text.getScaledWidth() * scaleFactorX)
    }
    else {
      this.text.scaleToHeight(this.text.getScaledHeight() * scaleFactorY)
    }
    this.centerInBox()
  },

  toObject: function() {
    return fabric.util.object.extend(this.callSuper("toObject"), {
      id: this.get("id"),
      sgid: this.get("sgid"),
      name: this.get("name")
    })
  }
})