import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"

// Connects to data-controller="select--images"
export default class extends Controller {

  connect() {
    var config = {
        render:{
          option: function(data, escape) {
            return `
              <div class="flex flex-row items-center gap-2">
                <div class="h-24 border-2 border-stone-400 text-center flex justify-center">
                    <img class="" src="${data.text}" />
                </div>
              </div>
            `
          },
          item: function(data, escape) {
            return `
              <div class="flex flex-row items-center pr-4" style="display: inline-flex">
                <div class="h-24 border-2 border-stone-400 text-center flex justify-center">
                    <img class="" src="${data.text}" />
                </div>
              </div>
            `
          },
        }
      }
      new TomSelect(this.element, config)
  }

}