import { Controller } from "@hotwired/stimulus"
import { enter, leave } from "el-transition"

export default class extends Controller {

  static targets = ["container"]
  
  connect() {
  }

  open() {
    this.containerTarget.toggleAttribute('open', true);
  }


  close(event) {
    if (event.target.tagName === 'INPUT'){
      return
    }
    this.containerTarget.toggleAttribute('open', false);
  }

  disconnect() {
    this.close();
  }
}


