import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js";

export default class extends Controller {
  static values = {
    merchant: String
  }

  updateForm() {
    const blank_id = this.element.value;
    const merchant_slug = this.merchantValue;
    const url = "/merchants/".concat(merchant_slug).concat('/product_designs/update_form_from_blank');
    const elements = document.querySelectorAll('[id*="blank_view_attribute_"]');
    const product_design_configuration_ids = Array.from(elements).map(element => element.id);
    const data = {
      blank_id: blank_id,
      product_design_configuration_ids: product_design_configuration_ids
    };

    let payload = {
      responseKind: "turbo-stream",
      body: { data: data }
    };

    post(url, payload).then((response) => {
      if (response.ok) {}
    });
  }
}