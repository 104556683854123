import Autosave from 'stimulus-rails-autosave'

export default class extends Autosave {
  connect() {
    super.connect()
  }

  save() {
    super.save()
  }
}